import { RegionCode } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwIcon, SwLink } from '@kaa/ui-flanders/components';
import get from 'lodash.get';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getConfig } from '../../../common/config';
import {
  REGION_CONTACT_A_PROVIDER_URL,
  REGION_CONTACT_US_URL,
  REGION_FAQ_URL,
  PASS_URL,
} from '../../constants';
import { dataTest } from '../../datatest/keys';

export const BxFunctionalHeaderSupportMobile = () => {
  const regionCode = get(getConfig(), 'buildConfig.regionCode') as RegionCode;
  const { t } = useTranslation();
  const [active, setActive] = useState(false);

  return (
    <li className={`support ${active ? 'active' : ''}`}>
      <SwLink
        aria-expanded={active}
        title={t(i18nKeys.navigation.support)}
        data-testid={dataTest.header.support}
        onClick={(e) => {
          e.preventDefault();
          setActive((active) => !active);
        }}
      >
        {' '}
        <SwIcon className="showMobile navItem-icon" icon={Icon.ARROW_DOWN} />
        {t(i18nKeys.navigation.support)}
      </SwLink>
      <ul className="site-nav__subnavigation">
        <li>
          <SwLink to={`tel:${t(i18nKeys.bl.general.helpCenter.phone)}`}>
            <strong>
              {t(i18nKeys.bl.header.support.contactList.callus.title, {
                phoneNumber: t(i18nKeys.bl.general.helpCenter.phone),
              })}
            </strong>
            <p>
              {t(i18nKeys.bl.header.support.contactList.callus.description)}
            </p>
          </SwLink>
          <SwIcon className="showMobile navItem-icon" icon={Icon.PHONE} />
        </li>
        <li>
          <SwLink to={t(REGION_CONTACT_US_URL[regionCode])}>
            <strong>
              {t(i18nKeys.header.support.contactList.contactus.title)}
            </strong>
            <p>
              {t(i18nKeys.header.support.contactList.contactus.description)}
            </p>
          </SwLink>
          <SwIcon className="showMobile navItem-icon" icon={Icon.EMAIL} />
        </li>
        <li>
          <SwLink to={t(PASS_URL[regionCode])}>
            <strong>{t(i18nKeys.header.support.contactList.pass.title)}</strong>
          </SwLink>
          <SwIcon className="showMobile navItem-icon" icon={Icon.LOCATION} />
        </li>
        <li>
          <SwLink to={t(REGION_CONTACT_A_PROVIDER_URL[regionCode])}>
            <strong>
              {t(i18nKeys.header.support.contactList.findProvider.title)}
            </strong>
            <p>
              {t(i18nKeys.header.support.contactList.findProvider.description)}
            </p>
          </SwLink>
          <SwIcon className="showMobile navItem-icon" icon={Icon.SEARCH} />
        </li>
        <li>
          <SwLink to={t(REGION_FAQ_URL[regionCode])}>
            <strong>
              {t(i18nKeys.header.support.contactList.helpCenter.title)}
            </strong>
            <p style={{ textAlign: 'left' }}>
              {t(i18nKeys.header.support.contactList.helpCenter.description)}
            </p>
          </SwLink>
          <SwIcon
            className="showMobile navItem-icon"
            icon={Icon.QUESTION_MARK}
          />
        </li>
      </ul>
    </li>
  );
};
