import React, { AllHTMLAttributes } from 'react';

type HeaderMenuButtonProps = AllHTMLAttributes<HTMLButtonElement>;

export const HeaderMenuButton = (props: HeaderMenuButtonProps) => {
  return (
    <button
      {...props}
      type="button"
      className="site-bar__toggleMobile showMobile"
    >
      <span />
      <span />
      <span />
    </button>
  );
};
