import { AvailableLanguages } from '@kaa/common/enums/AvailableLanguages';
import {
  EventAction,
  EventCategory,
  sendCustomInteractionToGTM,
} from '@kaa/core-app/customers/utils/google-analytics'; // TODO MOVE ME TO COMMON
import {
  SwButton,
  SwColumn,
  SwGrid,
  SwLayout,
  SwLink,
} from '@kaa/ui-flanders/components';
import React from 'react';
import { FooterProps } from '../../../types/footer';
import anysurferLogo from './assets/anysurfer.svg';
import brusselsLogoEn from './assets/logo--en.svg';
import brusselsLogoFr from './assets/logo--fr.svg';
import brusselsLogoNl from './assets/logo--nl.svg';
import sprbLogo from './assets/sprb.svg';
import './Footer.style.scss';

export type FooterBrusselsProps = FooterProps & {
  language?: AvailableLanguages;
  languages?: AvailableLanguages[];
  changeLanguage?: (lng: AvailableLanguages) => void;
};

const getLogoForLanguage = (lang = AvailableLanguages.FRENCH) => {
  switch (lang) {
    case AvailableLanguages.ENGLISH:
      return brusselsLogoEn;
    case AvailableLanguages.DUTCH:
      return brusselsLogoNl;
    default:
      return brusselsLogoFr;
  }
};

export const FooterBrussels = ({
  title,
  url,
  copyright,
  topLinks,
  bottomLinks,
  language,
  languages,
  changeLanguage,
  anysurfer,
}: FooterBrusselsProps) => {
  return (
    <footer id="sprb-footer">
      <div className="footer--top">
        <SwLayout>
          <SwGrid>
            <SwColumn>
              <div className="footer--top_wrapper vl-u-display-flex vl-u-flex-wrap-wrap vl-u-flex-v-space-between">
                {!!url && (
                  <div className="site-logo spacing">
                    <a
                      href={url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="site-logo__link"
                      title={title}
                      onClick={() =>
                        sendCustomInteractionToGTM(
                          EventCategory.FOOTER,
                          EventAction.CLICK,
                          url,
                        )
                      }
                    >
                      <img
                        src={getLogoForLanguage(language)}
                        alt={title}
                        className="site-logo__image"
                        draggable={false}
                      />
                    </a>
                  </div>
                )}
                {topLinks && !!topLinks.length && (
                  <ul className="footer--top_list">
                    {topLinks.map(({ label, url, ...rest }) => (
                      <li key={label}>
                        <SwButton
                          tagName="a"
                          to={url}
                          title={label}
                          {...rest}
                          onClick={() =>
                            sendCustomInteractionToGTM(
                              EventCategory.FOOTER,
                              EventAction.CLICK,
                              url,
                            )
                          }
                        >
                          {label}
                        </SwButton>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </SwColumn>
          </SwGrid>
        </SwLayout>
      </div>
      <div className="footer--bottom">
        <SwLayout>
          <SwGrid>
            <SwColumn>
              <div className="vl-u-display-flex vl-u-flex-wrap-wrap vl-u-flex-v-space-between">
                <div className="list__wrapper">
                  <p className="copyright">{copyright}</p>
                  {bottomLinks && !!bottomLinks.length && (
                    <ul className="list spacing">
                      {bottomLinks.map(({ label, url, ...rest }) => (
                        <li className="list__item" key={label}>
                          <SwLink
                            to={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="list__link"
                            title={label}
                            {...rest}
                            onClick={() =>
                              sendCustomInteractionToGTM(
                                EventCategory.FOOTER,
                                EventAction.CLICK,
                                url,
                              )
                            }
                          >
                            {label}
                          </SwLink>
                        </li>
                      ))}
                    </ul>
                  )}
                  {!!changeLanguage &&
                    !!language &&
                    !!languages &&
                    !!languages &&
                    !!languages.length && (
                      <ul className="list spacing lang-switch">
                        {languages.map((lng: AvailableLanguages) => (
                          <li key={lng} className="list__item">
                            <button
                              type="button"
                              onClick={() => changeLanguage(lng)}
                              className="list__link current-language"
                            >
                              {lng.toUpperCase()}
                            </button>
                          </li>
                        ))}
                      </ul>
                    )}
                </div>
                {!!anysurfer && (
                  <div className="logos spacing">
                    <ul className="logos__list vl-u-display-flex vl-u-flex-wrap-wrap vl-u-flex-align-center">
                      <li className="logos__item">
                        <a
                          href={anysurfer.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="logos__link"
                        >
                          <img
                            src={anysurferLogo}
                            alt={anysurfer.a11y}
                            className="logos__image"
                            draggable={false}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
                {!!url && (
                  <div className="logos spacing">
                    <ul className="logos__list vl-u-display-flex vl-u-flex-wrap-wrap vl-u-flex-align-center">
                      <li className="logos__item">
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="logos__link"
                          title={title}
                          onClick={() =>
                            sendCustomInteractionToGTM(
                              EventCategory.FOOTER,
                              EventAction.CLICK,
                              url,
                            )
                          }
                        >
                          <img
                            src={sprbLogo}
                            alt={title}
                            className="logos__image"
                            draggable={false}
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </SwColumn>
          </SwGrid>
        </SwLayout>
      </div>
      <div className="rainbow">
        <div className="rainbow__1" />
        <div className="rainbow__2" />
        <div className="rainbow__3" />
        <div className="rainbow__4" />
        <div className="rainbow__5" />
        <div className="rainbow__6" />
        <div className="rainbow__7" />
      </div>
    </footer>
  );
};
