import { AvailableLanguages } from '@kaa/common/enums/AvailableLanguages';
import {
  Icon,
  SwIcon,
  SwLink,
  SwPopover,
  SwPopverRenderProps,
} from '@kaa/ui-flanders/components';
import { motion } from 'framer-motion';
import React, { useState } from 'react';

const UlContainer = {
  hidden: { opacity: 1, scale: 0.5 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const LiItem = {
  hidden: { x: -40, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

type HeaderSwitchLanguageProps = {
  language: AvailableLanguages;
  languages: AvailableLanguages[];
  changeLanguage: (lng: AvailableLanguages) => void;
};

export const HeaderSwitchLanguage = ({
  language,
  languages,
  changeLanguage,
}: HeaderSwitchLanguageProps) => {
  const [langSelector, openLangSelector] = useState(false);

  if (languages.length === 1) {
    return (
      <div className="vl-u-float-right site-bar__link site-bar__lang">
        {language.toUpperCase()}
      </div>
    );
  }

  const availableLanguages = languages.filter(
    (availableLanguage: AvailableLanguages) => availableLanguage !== language,
  );

  return (
    <>
      <SwPopover
        className="site-bar__link site-bar__lang"
        offsetX="5"
        modRight
        icon={Icon.ARROW_DOWN}
        basicLabel={{
          tag: 'span',
          text: language.toUpperCase(),
        }}
      >
        {({ close }: SwPopverRenderProps) => (
          <ul className="vl-popover__link-list">
            {availableLanguages.map((availableLanguage: AvailableLanguages) => {
              return (
                <li
                  key={availableLanguage}
                  className="vl-popover__link-list__item"
                >
                  <SwLink
                    tagName="button"
                    type="button"
                    onClick={() => {
                      changeLanguage(availableLanguage);
                      close();
                    }}
                  >
                    {availableLanguage.toUpperCase()}
                  </SwLink>
                </li>
              );
            })}
          </ul>
        )}
      </SwPopover>

      {langSelector ? (
        <motion.ul
          className="site-bar__lang-mobile"
          variants={UlContainer}
          initial="hidden"
          animate="visible"
        >
          {availableLanguages.map((availableLanguage: AvailableLanguages) => {
            return (
              <motion.li key={availableLanguage} variants={LiItem}>
                <SwLink
                  tagName="button"
                  type="button"
                  onClick={() => {
                    changeLanguage(availableLanguage);
                  }}
                >
                  {availableLanguage.toUpperCase()}
                </SwLink>
              </motion.li>
            );
          })}
          <li>
            <SwLink
              tagName="button"
              type="button"
              onClick={() => openLangSelector(false)}
            >
              <SwIcon icon={Icon.ARROW_LEFT} />
            </SwLink>
          </li>
        </motion.ul>
      ) : (
        <button
          type="button"
          className="site-bar__lang-mobile"
          onClick={() => openLangSelector(true)}
        >
          {language.toUpperCase()}
          <SwIcon icon={Icon.ARROW_RIGHT} />
        </button>
      )}
    </>
  );
};
