import { SwColumn, SwGrid } from '@kaa/ui-flanders/components';
import React, { PropsWithChildren, ReactNode } from 'react';
import './style/Header.style.scss';

type HeaderProps = {
  mobileNav: boolean;
  functionalHeader?: ReactNode;
};

export const HeaderBrussels = ({
  mobileNav,
  children,
  functionalHeader,
}: PropsWithChildren<HeaderProps>) => {
  return (
    <div
      className={`vl-header ${
        mobileNav ? 'mobileNavIsOpen' : 'mobileNavIsClose'
      }`}
    >
      <div className="vl-header__wrapper">
        <SwGrid className="site-bar">
          {!!children && (
            <SwColumn
              className={`site-bar__navHolder ${
                mobileNav ? 'isOpen' : 'isClose'
              }`}
              width="12"
            >
              {children}
            </SwColumn>
          )}
          {!!functionalHeader && (
            <SwColumn width="12">{functionalHeader}</SwColumn>
          )}
        </SwGrid>
      </div>
    </div>
  );
};
