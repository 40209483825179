import { ConfigLoadFailedFallback } from '@kaa/core-app/common/components';
import React from 'react';

export const ConfigLoadFailedFallbackWithTranslation: React.FC = () => {
  return (
    <ConfigLoadFailedFallback
      mainTitle="Oups ! Nous rencontrons un problème"
      introduction="Nous travaillons d'arrache-pied pour rétablir le site"
      actionButton="Réessayez"
    />
  );
};
