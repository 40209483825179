import {
  Icon,
  SwButton,
  SwColumn,
  SwContainer,
  SwGrid,
  SwIcon,
  SwIntroduction,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';

export const SupportScreen = () => {
  return (
    <SwContainer>
      <SwGrid modStacked>
        <SwColumn width="10" widthS="12">
          <SwTitle tagName="h1" className="vl-u-spacer--medium">
            h1 - SourceSansProLight, 48px
          </SwTitle>
          <SwIntroduction className="vl-u-spacer--large">
            Introduction - SourceSansProRegular, 24px - Lorem ipsum dolor sit
            amet, consectetur adipiscing elit. Suspendisse bibendum eros nec
            mauris pretium laoreet.
          </SwIntroduction>

          <SwTitle tagName="h2">h2 - SourceSansProRegular, 34px</SwTitle>
          <SwTitle tagName="h3">h3 - SourceSansProSemi bold, 24px</SwTitle>
          <SwTitle tagName="h4">h4 - SourceSansProSemi bold, 20px</SwTitle>
          <SwTitle tagName="h5">h5 - SourceSansProSemi bold, 18px</SwTitle>
          <SwTitle tagName="h6">h6 - SourceSansProSemi bold, 16px</SwTitle>
        </SwColumn>

        <SwColumn width="10" widthS="12">
          <p>
            Body text - SourceSansProLight , 16px - Lorem ipsum dolor sit amet,
            consectetur adipiscing elit. Suspendisse bibendum eros nec mauris
            pretium laoreet. Proin fringilla augue et tellus malesuada ultrices.
            Sed laoreet libero ut accumsan eleifend. In a lobortis lacus.
            Pellentesque condimentum lorem nec sodales egestas. Fusce accumsan
            dignissim mauris, sed accumsan odio volutpat vitae. Aliquam volutpat
            sapien turpis, ac placerat libero fermentum ut. Ut luctus augue nec
            quam euismod pretium. Etiam sed nisi lacinia, feugiat nibh ac,
            mattis tortor. Cras dapibus porta feugiat. In non est auctor,
            tincidunt urna eu, imperdiet ante. In hac habitasse plat ea
            dictumst. Aenean dapibus blandit lacus, id congue arcu pharetra ac.
            Nulla pulvinar vitae leo quis pellentesque. Quisque eget risus
            vestibulum, mollis massa euismod, porttitor ipsum.
          </p>
        </SwColumn>
        <SwColumn width="10" widthS="12">
          <div className="vl-typography">
            <p>
              <strong>strong-tag</strong>
            </p>
            <p>
              <b>
                <SwIcon icon={Icon.WALLET} />
                <SwIcon icon={Icon.CHECK_CIRCLE} />
                b-tag
              </b>
            </p>
          </div>
        </SwColumn>

        <SwColumn width="10" widthS="12">
          <SwButton>Profile</SwButton>&nbsp;
          <SwButton modSecondary>Profile</SwButton>&nbsp;
          <SwButton modTertiary>Profile</SwButton>
        </SwColumn>
      </SwGrid>
    </SwContainer>
  );
};
