import { RegionCode } from '@kaa/api/customers';
import { AvailableLanguages, LokaliseKeys } from '@kaa/common/enums';
import { BuildConfig } from '@kaa/core-app/common/config';
import { APP_ID, AUTH_TENANT } from '@kaa/core-app/customers/constants';
import { getRouterPath, Routes } from '@kaa/core-app/customers/routes';

export const buildConfig: BuildConfig<RegionCode> = {
  id: APP_ID.BE_BRU,
  isDevelopment: process.env.NODE_ENV !== 'production',
  buildId: process.env.REACT_APP_BUILD_ID as string,
  regionCode: RegionCode.BE_BRU,
  lokaliseKey: LokaliseKeys.BRUSSELS,
  origin: window.origin,
  forgotPasswordUrl:
    'https://extranet.titresservices.brussels/Home/LostPassword',
  loginUrl: getRouterPath(Routes.LOGIN),
  logoutUrl: getRouterPath(Routes.LOGOUT),
  unauthorizedRedirectUrl: getRouterPath(Routes.LOGIN),
  errorPagePath: getRouterPath(Routes.PAGE_404),
  i18n: {
    availableLanguages: [AvailableLanguages.DUTCH, AvailableLanguages.FRENCH],
    defaultLanguage: AvailableLanguages.FRENCH,
    fallbackLanguage: AvailableLanguages.FRENCH,
  },
  oidc: {
    acr_values: AUTH_TENANT,
    redirect_uri: `${window.location.origin}${getRouterPath(
      Routes.LOGIN_CALLBACK,
    )}`,
    post_logout_redirect_uri: `${window.location.origin}${getRouterPath(
      Routes.LOGOUT_CALLBACK,
    )}`,
    silent_redirect_uri: `${window.location.origin}${getRouterPath(
      Routes.SILENT_RENEW_HTML_CALLBACK,
    )}`,
  },
};
