import { SwLink } from '@kaa/ui-flanders/components';
import brusselsLogo from '@kaa/ui-flanders/themes/bl/images/logo.svg';
import logo from '@kaa/ui-flanders/themes/_shared/images/Pluxee_Logo_DarkBlue_RGB.png';
import React from 'react';

type HeaderLogoProps = {
  publicUrl: string;
  homeUrl: string;
  title: string;
  titleMobile: string;
  mainLogoAlt: string;
  logoAlt: string;
};

export const HeaderLogo = ({
  publicUrl,
  homeUrl, // TODO align with wallonia
  title,
  titleMobile,
  mainLogoAlt,
  logoAlt,
}: HeaderLogoProps) => {
  return (
    <SwLink
      to={publicUrl}
      target="_blank"
      className="site-bar__logoLink site-bar__link"
    >
      <img className="site-bar__image" src={brusselsLogo} alt={mainLogoAlt} />
      <div className="site-bar__separator">
        <span className="site-bar__title hideMobile">{title}</span>
        <span className="site-bar__title site-bar__mobileTitle showMobile">
          {/* TODO align with wallonia (not clickable) */}
          <img src={logo} alt={logoAlt} className="logo_pluxee" />
          {titleMobile}
        </span>
      </div>
    </SwLink>
  );
};
