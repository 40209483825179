import { Customer } from '@kaa/api/customers';
import { i18nKeys } from '@kaa/i18n/customers/keys';
import { Icon, SwIcon } from '@kaa/ui-flanders/components';
import React from 'react';
import { useTranslation } from 'react-i18next';

type HeaderSwitchProfileUserCardProps = {
  customer: Customer;
};

export const HeaderSwitchProfileUserCard = ({
  customer,
}: HeaderSwitchProfileUserCardProps) => {
  const { t } = useTranslation();
  return (
    <>
      <SwIcon
        className="user-profile__badge"
        modAfter
        modLarge
        icon={Icon.USER_CIRCLE}
      />
      <p>
        <strong className="user-profile__name">
          {customer.firstName} {customer.lastName}
        </strong>
        <span className="user-profile__customerId">
          {t(i18nKeys.bl.switchProfile.modal.customerId)} {customer.id}
        </span>
      </p>
    </>
  );
};
