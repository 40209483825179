import {
  Icon,
  SwIconList,
  SwIconListItem,
  SwLink,
  SwPopover,
  SwTitle,
} from '@kaa/ui-flanders/components';
import React from 'react';

type HeaderSupportLink = {
  url: string;
  label: string;
  icon: string;
  description?: string;
  target?: string;
};

type HeaderSupportProps = {
  title: string;
  subtitle: string;
  popoverTitle: string;
  popoverIcon: Icon;
  links?: HeaderSupportLink[];
};

export const HeaderSupport = ({
  title,
  subtitle,
  popoverTitle,
  popoverIcon,
  links,
}: HeaderSupportProps) => {
  return (
    <SwPopover
      icon={popoverIcon}
      className="vl-u-float-right site-bar__link  site-bar__support hideMobile"
      offsetX="-15"
      modRight
      modClosable
      basicLabel={{
        tag: 'span',
        text: popoverTitle.toUpperCase(),
      }}
    >
      <div>
        <SwTitle tagName="h4" className="vl-u-hr vl-u-spacer--small">
          {title}
        </SwTitle>
        <SwTitle tagName="h5" className="vl-u-spacer">
          {subtitle}
        </SwTitle>
        {links && !!links.length && (
          <SwIconList>
            {links.map(({ url, icon, label, description, target, ...rest }) => (
              <SwIconListItem key={url} icon={icon} modLarge {...rest}>
                <SwLink
                  to={url}
                  target={target}
                  rel={target === '_blank' ? 'noopener noreferrer' : undefined}
                >
                  {label}
                </SwLink>
                {!!description && <p>{description}</p>}
              </SwIconListItem>
            ))}
          </SwIconList>
        )}
      </div>
    </SwPopover>
  );
};
